<template>
    <!-- 添加/编辑型号 -->
    <div class="addEditModel">
        <div class="mask"></div>
        <div class="addEditAccountCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelAccountFun"></i>
            </div>
            <el-form :model="agentListData" ref="agentListData" :rules="rules">
                <el-form-item label="型号名称" prop="remark">
                    <el-input
                        v-model="agentListData.remark"
                        placeholder="请输入型号名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="生产厂商" prop="manufacturerName">
                    <el-input
                        v-model="agentListData.manufacturerName"
                        placeholder="请输入软件版本"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备编号" prop="appType">
                    <el-input
                        v-model="agentListData.appType"
                        placeholder="请输入设备编号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="软件版本" prop="appVersion">
                    <el-input
                        v-model="agentListData.appVersion"
                        placeholder="请输入软件版本"
                    ></el-input>
                </el-form-item>
                <el-form-item label="版本名称" prop="versionName">
                    <el-input
                        v-model="agentListData.versionName"
                        placeholder="请输入版本名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="下载地址" prop="downUrl">
                    <el-input
                        v-model="agentListData.downUrl"
                        placeholder="请输入下载地址"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelAccountFun">取 消</el-button>
                <el-button type="primary" @click="deterAccountFun"
                    >提 交</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'addEditModel',
    props: {
        // 标题
        catdTitle: {
            type: String,
            default: '编辑型号',
        },
        // 型号信息
        agentList: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            // 关联代理商
            agent: '',
            agents: [],
            // 表单
            agentListData: {
                // 型号名称
                remark: '',
            },
            rules: {
                remark: [
                    {
                        required: true,
                        message: '请输入型号名称',
                        trigger: 'blur',
                    },
                ],
                manufacturerName: [
                    {
                        required: true,
                        message: '请输入生产厂商',
                        trigger: 'blur',
                    },
                ],
                appType: [
                    {
                        required: true,
                        message: '请输入设备编号',
                        trigger: 'blur',
                    },
                ],
                appVersion: [
                    {
                        required: true,
                        message: '请输入软件版本',
                        trigger: 'blur',
                    },
                ],
                versionName: [
                    {
                        required: true,
                        message: '请输入版本名称',
                        trigger: 'blur',
                    },
                ],
                downUrl: [
                    {
                        required: true,
                        message: '请输入下载地址',
                        trigger: 'blur',
                    },
                ],
            },
            // 被选中的企业/组织id
            companyId: null,
            // 关联代理商id
            agentId: null,
        }
    },
    created() {
        // console.log('this.agentList: ', this.agentList)
        const agentListData = {
            ...this.agentList,
        }
        if (this.catdTitle === '编辑型号') {
            this.agentListData = agentListData
        }
    },
    methods: {
        // 点击取消
        cancelAccountFun() {
            this.$emit('cancelAccount-fun')
        },
        // 点击提交
        deterAccountFun() {
            if (
                !this.agentListData.remark ||
                !this.agentListData.manufacturerName ||
                !this.agentListData.appType ||
                !this.agentListData.appVersion ||
                !this.agentListData.versionName ||
                !this.agentListData.downUrl
            ) {
                this.$message({
                    message: '必填项不能为空！',
                    type: 'error',
                })
                return false
            }

            let url = '/v1/app_version/add'
            let url2 = '/v1/app_version/edit'
            let value = {
                remark: this.agentListData.remark,
                manufacturerName: this.agentListData.manufacturerName,
                appType: this.agentListData.appType,
                appVersion: this.agentListData.appVersion,
                versionName: this.agentListData.versionName,
                downUrl: this.agentListData.downUrl,
            }
            if (this.catdTitle === '添加型号') {
                this.addEditPost(value, url)
            } else if (this.catdTitle === '编辑型号') {
                this.addEditPost({ ...value, id: this.agentList.id }, url2)
            }
        },
        // 添加/编辑代理商
        addEditPost(value, url) {
            this.$instance
                .post(url, value)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$emit('deterAccount-fun')
                        this.$message({
                            message: this.catdTitle + '成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.addEditModel {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    // 弹窗卡片
    & ::v-deep .addEditAccountCard {
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 6px;
        // 头部
        .cardHeader {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 40px;
            box-sizing: border-box;
            border-radius: 6px 6px 0 0;
            // 头部-icon
            .el-icon-close {
                font-size: 24px;
            }
        }
        .el-form {
            padding: 30px 40px 10px;
            border-top: 1px #f0f0f0 solid;
            border-bottom: 1px #f0f0f0 solid;
            .el-form-item {
                margin-bottom: 20px;
                .el-form-item__label {
                    width: 90px !important;
                    padding-right: 20px;
                    line-height: 32px;
                }
                .el-input__inner {
                    line-height: 32px !important;
                }
                .el-form-item__content {
                    margin-left: 90px !important;
                    line-height: 32px;
                    .el-select {
                        width: 100%;
                    }
                    // 搜索图标
                    .el-icon-search {
                        width: 34px;
                        position: absolute;
                        right: 0px;
                        text-align: center;
                        z-index: 2;
                        cursor: pointer;
                    }
                    .addressSty {
                        i {
                            display: none;
                        }
                    }
                    input {
                        height: 32px;
                    }
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .button {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;
        .el-button {
            line-height: 30px;
            padding: 0 20px;
        }
    }
}
</style>
