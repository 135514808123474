<template>
    <!-- 设备型号管理 -->
    <div class="model-admin">
        <div class="accountSearch">
            <el-button
                @click="addDoorClick"
                class="titleButton"
                type="primary"
                icon="el-icon-download"
                >添加型号</el-button
            >
        </div>
        <div class="accountTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="deviceData.elements"
                    style="width: 100%"
                    height="100%"
                >
                    <el-table-column
                        prop="id"
                        label="ID"
                        align="center"
                        min-width="60"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="型号名称"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="manufacturerName"
                        label="生产厂商"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="appVersion"
                        label="软件版本"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="versionName"
                        label="版本名称"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="appType"
                        label="设备编号"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <!-- <el-table-column
                        prop="downUrl"
                        label="下载地址"
                        min-width="200"
                    >
                    </el-table-column> -->
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button
                                @click="editClick(scope.row, scope.$index)"
                                type="text"
                                >编辑</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                :current-page="currentPage"
                :page-size="deviceData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="deviceData.totalRecords"
            >
            </el-pagination>
        </div>
        <!-- 弹窗 添加/编辑型号 -->
        <addEditModel
            v-if="addEditModelShow"
            @cancelAccount-fun="cancelAccountFun"
            @deterAccount-fun="deterAccountFun"
            :catdTitle="catdTitle"
            :agentList="deviceData.elements[agentId]"
        ></addEditModel>
    </div>
</template>
<script>
import addEditModel from '../../components/addEditModel'

export default {
    name: 'model-admin',
    components: { addEditModel },
    data() {
        return {
            loading: false,
            // 是否显示 弹窗 添加/编辑型号
            addEditModelShow: false,
            // 拉取到的 表格数据
            deviceData: {},
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
            // 弹窗 标题
            catdTitle: '',
            // 被选中下标
            agentId: null,
        }
    },
    created() {
        this.equipmentList()
    },
    methods: {
        // 弹窗 添加/编辑型号 取消
        cancelAccountFun() {
            this.addEditModelShow = false
        },
        // 弹窗 添加/编辑型号 提交
        deterAccountFun() {
            this.addEditModelShow = false
            this.equipmentList()
        },
        // 点击 添加型号
        addDoorClick() {
            this.catdTitle = '添加型号'
            this.addEditModelShow = true
        },
        // 点击 编辑型号
        editClick(val, index) {
            this.agentId = index
            this.catdTitle = '编辑型号'
            this.addEditModelShow = true
        },
        // 设备型号管理 列表
        equipmentList() {
            this.loading = true

            this.$instance
                .get('/v1/app_version/list-page', {
                    params: {
                        pageNum: this.currentPage - this.start,
                        pageSize: 10,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.loading = false
                        this.deviceData = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.equipmentList()
        },
    },
}
</script>
<style lang="scss" scoped>
.model-admin {
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    // 搜索
    & ::v-deep .accountSearch {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        box-sizing: border-box;
        border-bottom: 1px #f0f0f0 solid;
        .el-button--primary {
            line-height: 32px;
            padding: 0 12px;
            border: unset;
        }
        .titleSearch {
            display: flex;
            .el-select > .el-input,
            .el-input {
                margin-right: 6px;
            }
            .el-select > .el-input {
                width: 150px;
            }
            .el-input {
                width: 240px;
                .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                }
                .el-input__suffix {
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .accountTableC {
        width: calc(100% - 40px);
        height: calc(100% - 144px);
        margin: 20px;
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .el-table td,
            .el-table th {
                padding: 4px 0;
            }
        }
        // 翻页
        .el-pagination {
            text-align: right;
            margin-top: 10px;
            padding: unset;
        }
    }
}
</style>
